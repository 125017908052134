<template>
    <v-card>
        <v-card-title>Stripeアカウント作成</v-card-title>
        <v-card-text>
            <div v-if="!data.settingData.stripe.accountCreatePending && !data.settingData.stripe.connectedAccountId">
                <!-- <v-btn @click="method.stripe.createAccount" :disabled="!loginCheck">クレジット決済のためのアカウント作成を作る</v-btn> -->
            </div>
            <div id="embedded-onboarding-container" />
            <p v-if="data.settingData.stripe.error" class="error">エラーが発生しました!</p>
            <div v-if="data.settingData.stripe.connectedAccountId || data.settingData.stripe.accountCreatePending" class="dev-callout">
                <!-- <v-btn @click="method.stripe.createAccount">アカウント設定</v-btn> -->
            </div>
        </v-card-text>
    </v-card>
</template>

<script setup lang="ts">
import {data} from '@/comporsables/useData'
import {method} from '@/comporsables/useMethod'
import { computed } from 'vue';

const loginCheck = computed(() => {
    return data.loginUser.uid !== '';
});
</script>


