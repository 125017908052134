<template>
    <v-container>
        <v-row>
            <v-col>
                <v-card>
                    <v-card-title>商品購入ページ</v-card-title>
                    <v-card-text>
                        <v-form>
                            <v-radio-group v-model="data.reserveUser.selectedPlan">
                                <div v-for="plan in data.loginUser.reservePlans" :key="plan.id">
                                    <v-radio v-if="plan.shipping" :label="`${plan.title} - ${plan.price}円`" :value="plan"></v-radio>
                                    <v-textarea readonly v-model="plan.description" v-if="plan.shipping"></v-textarea>
                                </div>
                            </v-radio-group>
                            <v-text-field
                            v-model="data.reserveUser.name"
                                label="名前"
                            >
                            </v-text-field>
                            <v-text-field
                                v-model="data.reserveUser.email"
                                label="メールアドレス"
                            ></v-text-field>
                        </v-form>
                        
                    </v-card-text>
                    <v-card-actions>
                        <v-btn @click="method.reserve.submitSection1(router,data.reserveUser.selectedPlan),
                                        data.reserveUser.submit = true,
                                        data.reserveUser.selectedPlan.paymentOnly = true"
                            :disabled="!data.reserveUser.name || !data.reserveUser.email || !data.reserveUser.selectedPlan.id || data.reserveUser.submit"
                        >決済ページへ移動する</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '../comporsables/useData'
import { method } from '../comporsables/useMethod'
import { onMounted } from 'vue'
import { useRouter,useRoute } from 'vue-router';
const route = useRoute()
const router = useRouter();

onMounted(async () => {
    localStorage.removeItem('reserveData')
    localStorage.removeItem('reserveUser')
    localStorage.removeItem('settingData')
    console.log('onMounted',route.params.uid)
    const calendarId = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid;
    data.reserveUser.uid = calendarId;
    await method.shipping.load.plan();
})
</script>