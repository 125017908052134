<template>
      <v-container fill-height>
        <v-row align-content="center" justify="center">
          <v-col align="center">
            <v-card v-if="!data.login.check">
              <v-card-text>
                <v-container>
                <v-row justify="center">
                  <v-col align="right" align-self="center" xs="12" lg="3">
                    メールアドレス
                  </v-col>
                  <v-col align="left" xs="12" lg="4">
                    <v-text-field v-model="data.loginUser.email"></v-text-field>
                  </v-col>
                </v-row>
                <v-row justify="center" v-show="!data.loginUser.resetPassword">
                  <v-col align="right" align-self="center" xs="12" lg="3">
                    パスワード
                  </v-col>
                  <v-col align="left" xs="12" lg="4">
                    <v-text-field v-model="data.loginUser.password" type="password"></v-text-field>
                  </v-col>
                </v-row>
              </v-container>
              </v-card-text>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn 
                  v-show="!data.loginUser.resetPassword"
                  @click="method.login(data.loginUser.email,data.loginUser.password)" 
                  :disabled="data.login.button">
                  ログインする
                </v-btn>
                <v-btn 
                  v-show="data.loginUser.resetPassword"
                  @click="method.resetPassword()" 
                  :disabled="!data.loginUser.email.trim()">
                  リセットメールを送信する
                </v-btn>
                <v-btn 
                  @click="data.loginUser.resetPassword = !data.loginUser.resetPassword"
                  v-show="!data.loginUser.resetPassword"
                  >
                  パスワードを忘れた場合
                </v-btn>
                <v-btn 
                  @click="data.loginUser.resetPassword = !data.loginUser.resetPassword"
                  v-show="data.loginUser.resetPassword"
                  >
                  ログイン画面に戻る
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
            <v-card v-else-if="data.login.check">
                <v-card-text>
                  ログインしました。
                </v-card-text>
                <v-card-title>カレンダーページURL</v-card-title>
                <v-card-text>
                  <v-text-field
                    lg="6"
                    xs="12"
                    :readonly="true"
                    append-inner-icon="mdi-content-copy"
                    v-model="url"
                    @click="method.dashboardCalendar.copyUrl(1,url)"
                  >設置URL
                  <v-tooltip
                    activator="parent"
                    :open-on-hover="false"
                    location="end"
                    v-model="data.dashboardCalendar.tooltip1"
                  >コピーしました</v-tooltip>
                  </v-text-field>
                </v-card-text>
                <v-card-title>プラン購入専用ページURL(カレンダーからの時間予約無しで、決済のみ可能なページのURLです。)</v-card-title>
                <v-card-text>
                  <v-text-field
                    lg="6"
                    xs="12"
                    :readonly="true"
                    append-inner-icon="mdi-content-copy"
                    v-model="shippingUrl"
                    @click="method.dashboardCalendar.copyUrl(3,shippingUrl)"
                  >設置URL
                  <v-tooltip
                    activator="parent"
                    :open-on-hover="false"
                    location="end"
                    v-model="data.dashboardCalendar.tooltip3"
                  >コピーしました</v-tooltip>
                  </v-text-field>
                </v-card-text>
                <v-card-title>
                  条件付きURL(使用したいプランを選択してください。下記URLはチェックの入っているプランのみ、カレンダーに表示されます。)
                </v-card-title>
                <v-card-text>
                  <v-checkbox
                    lg="6"
                    xs="12"
                    v-for="plan in data.loginUser.reservePlans"
                    :key="plan.id" 
                    :label="plan.title"
                    :value="plan.id"
                    v-model="data.loginUser.selectedUse"></v-checkbox>
                  <v-text-field
                    lg="6"
                    xs="12"
                    :readonly="true"
                    append-inner-icon="mdi-content-copy"
                    v-model="selectedUrl"
                    @click="method.dashboardCalendar.copyUrl(2,selectedUrl)"
                  >設置URL
                  <v-tooltip
                    activator="parent"
                    :open-on-hover="false"
                    location="end"
                    v-model="data.dashboardCalendar.tooltip2"
                  >コピーしました</v-tooltip>
                  </v-text-field>
                </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
  </template>

  <script setup lang=ts>
  import { data } from '@/comporsables/useData'
  import { method } from '@/comporsables/useMethod'
  import { computed } from 'vue'

  const baseUrl = 'https://reservev2.infom.jp'

  const url = computed(() => {
    return `${baseUrl}/${data.loginUser.uid}/`
  });

  const shippingUrl = computed(() => {
    return `${baseUrl}/shipping/${data.loginUser.uid}/`
  });

  const selectedUrl = computed(() => {
    let urlTemp = `${baseUrl}/${data.loginUser.uid}/?`;
    if (data.loginUser.selectedUse.length > 0) {
      urlTemp = data.loginUser.selectedUse.reduce((acc, use,index) => {
        const paramKey = `plan${index}`
        return acc + `${paramKey}=${use}`;
      }, urlTemp)
  }
  return urlTemp;
  });


  

  </script>