<template>
    <v-container>
        <v-row>
            <v-col>
                <full-calendar
                    :options="data.dashboardCalendar"
                ></full-calendar>
                    <v-dialog v-model="data.dashboardCalendar.showModal" max-width="500px">
                        <v-card lg="6">
                            <div v-for="(item,index) in data.dashboardCalendar.selectedEventDetails" :key="item.id">
                            <v-card-title>{{ item.startStr }} {{ item.title }}</v-card-title>
                            <v-card-text>
                                <v-form>
                                    <v-text-field
                                        v-model="item.month"
                                        maxlength="2"
                                        suffix="月"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="item.day"
                                        maxlength="2"
                                        suffix="日"
                                    ></v-text-field>
                                    <v-text-field
                                    type="number"
                                    
                                        v-model="item.startHour"
                                        label="開始時間"
                                        maxlength="2"
                                        suffix="時"
                                        required
                                        class="d-span"
                                    />
                                    <v-text-field
                                    type="number"
                                    
                                        v-model="item.startMinute"
                                        label="開始分"
                                        maxlength="2"
                                        suffix="分"
                                        required
                                    /><v-text-field
                                    type="number"
                                    
                                        v-model="item.endHour"
                                        label="終了時間"
                                        maxlength="2"
                                        suffix="時"
                                        required
                                    />
                                    <v-text-field
                                    type="number"
                                    
                                        v-model="item.endMinute"
                                        label="終了分"
                                        maxlength="2"
                                        suffix="分"
                                        required
                                    />
                                </v-form>
                                <v-radio-group v-model="item.selectedPlan">
                                    <div v-for="plan in item.plan" :key="plan.id">
                                        <v-radio :label="`${plan.title} - ${plan.price}円`" :value="plan.id"></v-radio>
                                    </div>
                                </v-radio-group>
                            </v-card-text>
                            </div>
                            <v-card-actions>
                                <v-btn color="primary" @click="data.dashboardCalendar.showModal = false">キャンセル</v-btn>
                                <v-btn @click="method.dashboardCalendar.save.event.edit()">変更する</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                    <v-dialog v-model="data.dashboardCalendar.addReserveModal" max-width="500px">
                        <v-card lg="6">
                            <v-card-title>新規追加</v-card-title>
                            <v-card-text v-for="item in data.dashboardCalendar.selectedEventDetails">
                                <v-form>
                                    <v-text-field
                                        v-model="item.month"
                                        maxlength="2"
                                        suffix="月"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="item.day"
                                        maxlength="2"
                                        suffix="日"
                                    ></v-text-field>
                                    <v-text-field
                                        v-model="item.startHour"
                                        label="開始時間"
                                        maxlength="2"
                                        suffix="時"
                                        required
                                        class="d-span"
                                    />
                                    <v-text-field
                                        v-model="item.startMinute"
                                        label="開始分"
                                        maxlength="2"
                                        suffix="分"
                                        required
                                    /><v-text-field
                                        v-model="item.endHour"
                                        label="終了時間"
                                        maxlength="2"
                                        suffix="時"
                                        required
                                    />
                                    <v-text-field
                                        v-model="item.endMinute"
                                        label="終了分"
                                        maxlength="2"
                                        suffix="分"
                                        required
                                    />
                                    <v-radio-group v-model="item.selectedPlan" required>
                                    <div v-for="plan in item.plan" :key="plan.id">
                                        <v-radio :label="`${plan.title} - ${plan.price}円`" :value="plan.id"></v-radio>
                                    </div>
                                </v-radio-group>
                                </v-form>
                            </v-card-text>
                            <v-card-actions>
                                <v-btn color="primary" @click="data.dashboardCalendar.addReserveModal = false">キャンセル</v-btn>
                                <v-btn @click="method.dashboardCalendar.save.event.new()">追加する</v-btn>
                            </v-card-actions>
                        </v-card>

                    </v-dialog>
                    {{ data.dashboardCalendar.menuY }}
                <div
                    v-if="data.dashboardCalendar.menu"
                    class="context-menu"
                    :style="{ top: data.dashboardCalendar.menuY + 'px', left: data.dashboardCalendar.menuX + 'px' }"
                    ref="menuRef"
                >
                    <v-list>
                        <v-list-item
                        v-for="(item, index) in data.dashboardCalendar.menuItems"
                        :key="index"
                        @click="method.dashboardCalendar.menuAction(item.action,item.date)"
                        >
                        <v-list-item-title>{{ item.text }}</v-list-item-title>
                        </v-list-item>
                    </v-list>
                </div>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '@/comporsables/useData'
import { method } from '@/comporsables/useMethod'
import FullCalendar from '@fullcalendar/vue3'
import { useRouter } from 'vue-router';
import { onMounted, onUnmounted,ref } from 'vue';
const router = useRouter();

const menuRef = ref(null);

const handleClickOutside = (event:any) => {
      if (menuRef.value && !menuRef.value.contains(event.target)) {
        data.dashboardCalendar.menu = false;
      }
    };

onMounted(() => {
      document.addEventListener('click', handleClickOutside);
    });

    onUnmounted(() => {
      document.removeEventListener('click', handleClickOutside);
    });

</script>

<style scoped>
.context-menu {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 2px 5px rgba(0,0,0,.2);
}

.v-list-item {
  cursor: pointer;
}

.v-list-item:hover {
  background-color: #f0f0f0;
}
</style>