import { initializeApp } from 'firebase/app'
import { getFirestore, serverTimestamp,DocumentData } from 'firebase/firestore'
import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, sendPasswordResetEmail, signOut } from 'firebase/auth'
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";
import dayjs from 'dayjs'

import { data } from './useData'
import { method } from './useMethod'

const firebaseApp = initializeApp(
  {
    apiKey: "AIzaSyDk93wPbVFCK7Srjhc1dGM6ZS0NWCKjvmw",
    authDomain: "homepagelp.firebaseapp.com",
    projectId: "homepagelp",
    storageBucket: "homepagelp.appspot.com",
    messagingSenderId: "494662465260",
    appId: "1:494662465260:web:777d8d2c4fc9d8f22717c9"
  }
)

export const app = firebaseApp;
// console.log('app',app)
// ドメインがlocalhostの場合は、デバッグトークンを有効にする
// localhostかどうかをチェック
if (location.hostname === "localhost") {
    console.log('localhost');
    // インデックスシグネチャを使用してプロパティを設定
    (window as any)['FIREBASE_APPCHECK_DEBUG_TOKEN'] = true;
  }
  

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider('6LcRspgcAAAAANqqpiNxqJzwaCYavrzOMsCxpC8c'),
  isTokenAutoRefreshEnabled: true
});

export const token = appCheck;
const auth = getAuth();


import {doc,getDoc,setDoc,getDocs,addDoc,deleteDoc,collection,updateDoc,query,where} from 'firebase/firestore';
import { set } from 'lodash';
// import { event } from 'firebase-functions/v1/analytics';

const firestoreDB = getFirestore(firebaseApp);
const collections = {
        customer : collection(firestoreDB, 'customerV2'),
        customerSecret : collection(firestoreDB, 'customerSecretV2'),
        reserveDays : collection(firestoreDB, 'reserveDaysV2'),
        plans: collection(firestoreDB, 'plansV2'),
        orders: collection(firestoreDB, 'ordersV2'),
    }

export const firebase = ({
    async userLogin(mail:string,password:string){
      try{
        return await signInWithEmailAndPassword(auth, mail, password)
        .then( userCredential => {
           console.log('ログイン成功',userCredential)
           return userCredential;
        })
      }catch(e){
        alert('ログイン出来ませんでした。メールアドレスとパスワードをご確認下さい')
        data.login.button = false;
      }
      },
      async userLogout(){
        return await signOut(auth).then( () => {
           return true;
        }).catch( e => {
          console.log('ログアウトエラー',e)
          return false;
        })
      },
      async userRegister(mail:string,password:string){
        return await createUserWithEmailAndPassword(auth, mail, password).then( userCredential => {
           console.log('登録成功',userCredential);
            return userCredential;
        }, e => {
          console.log('登録エラー',e)
        }
        )
      },
      async resetPassword() {
        try {
          await sendPasswordResetEmail(auth, data.loginUser.email);
          console.log('パスワードリセットメールを送信しました');
          alert('パスワードリセットメールを送信しました');
        } catch (e) {
          console.log('パスワードリセットエラー', e);
          alert('パスワードリセットエラー');
        }
      },      
      firestore:{
        save:{
            event:{
                async new(docRef:any,events:any){
                  console.log('firestore save new',docRef,events)
                    for (const event of events) {
                        event.timeStamp = serverTimestamp();
                        await setDoc(docRef, event,{merge:true});
                    }
                },
                async getNewDocID(collection=collections.reserveDays){
                    const docRef = doc(collection);
                    return docRef;
                },
                async edit(event:any){
                  const docRef= doc(collections.reserveDays,event.id);
                  await setDoc(docRef,event,{merge:true});
                },
            },
            async plan(){
              if(!data.loginUser.uid){
                alert('ログインしていません');
                return
              }
              for(const plan of data.loginUser.reservePlans){
                console.log('save plan',plan)
                   if(plan.id){
                    const docRef = doc(collections.plans,plan.id);
                    await setDoc(docRef,plan,{merge:true});
                  }else if(!plan.id){
                    const docRef = await this.event.getNewDocID(collections.plans);
                    plan.id = docRef.id;
                    plan.uid = data.loginUser.uid;
                    await setDoc(docRef, plan, {merge:true});
                    console.log('save plan',docRef)
                  }
                }
                
              },
              async settingData(){
                if(!data.loginUser.uid){
                  alert('ログインしていません');
                  return false;
                }
                const docRef = doc(collections.customer,data.loginUser.uid);
                await setDoc(docRef,data.settingData,{merge:true});
                return true;
              },
              async customerSecret(){
                if(!data.loginUser.uid){
                  alert('ログインしていません');
                  return false;
                }
                const customerSecret = method.dashboard.filterObject(data.customerSecret)
                const docRef = doc(collections.customerSecret,data.loginUser.uid);
                await setDoc(docRef,customerSecret,{merge:true});
                return true;
              },
              reserve:{
                async tempCheck(reserveDateId:string){
                  const docRef = doc(collections.reserveDays,reserveDateId);
                  const snapShot = await getDoc(docRef);
                  const reserveData = snapShot.data();
                  if(reserveData === undefined){
                    return false;
                  }
                  console.log('reserveData',reserveData)
                  if(reserveData.reserveUser.email){
                    alert('残念ですが予約が入っています')
                    return false;
                  }
                },
                async tempSave(reserveDateId:string){
                  console.log('tempSave',reserveDateId)
                  if(!reserveDateId){
                    alert('予約日のIDが不正です');
                    return
                  }
                  const docRef = doc(collections.reserveDays,reserveDateId);
                  await setDoc(docRef,{email:data.reserveUser.email},{merge:true});
                  return true;
                },
                async tempReturn(reserveDateId:string){
                  if(!reserveDateId){
                    alert('予約日のIDが不正です');
                    return
                  }
                  // data.reserveUser = {};
                  const docRef = doc(collections.reserveDays,reserveDateId);
                  await setDoc(docRef,{email:''},{merge:true});
                  return true;
              },
              async reserveSave(reserveDateId?:string) {
                const ordersDoc = await firebase.firestore.save.event.getNewDocID(collections.orders);
                data.orderFinishUser.reserveId = reserveDateId || '';
                data.orderFinishUser.orderId = ordersDoc.id || '';
                console.log('reserveSave', data.orderFinishUser);
                await setDoc(ordersDoc, data.orderFinishUser, { merge: true });
                if(reserveDateId){
                  const reserveDoc = doc(collections.reserveDays, reserveDateId);
                  await setDoc(reserveDoc, { orderId: ordersDoc.id }, { merge: true });
                }
                return true;
            },
            },
            async order(){
              const orderDoc = doc(collections.orders);
              await setDoc(orderDoc,data.reservedPage.editedItem,{merge:true});
            },
        },
        load:{
            async userData(){
              console.log('userData',data.loginUser.uid)
                const docRef = doc(collections.customer, data.loginUser.uid);
                const userData = await getDoc(docRef);
                if(userData){
                  return userData.data();
                } else {
                  return false;
                }
                
            },
            async plans(){
              const q = query(collections.plans,where('uid','==',data.loginUser.uid || data.reserveUser.uid));
              const plans = await getDocs(q);
              return plans.docs.map(doc => {
                const data = doc.data();
                return {
                  id: doc.id,
                  title: data.title,
                  price: data.price,
                  description: data.description,
                  delete:data.delete,
                  use:data.use,
                  color:data.color,
                  shipping:data.shipping,
                }
              });
            },
            async settingData(){
              console.log('settingData',data.reserveUser.uid,data.loginUser.uid);
              const uid = data.reserveUser.uid || data.loginUser.uid;
              const docRef = doc(collections.customer,uid);
              const settingData = await getDoc(docRef);
              if(settingData){
                console.log('settingData',settingData)
                data.settingData = {...data.settingData,...settingData.data()};
                if(data.settingData.stores.use === undefined){
                  data.settingData.stores.use = false;
                }
                console.log('data.settingData',data.settingData)
                return data.settingData;
              } else {
                return false;
              }
            },
            event:{
              async reserver(){
                const today = dayjs().toDate();
                const q = query(collections.reserveDays,
                  where('start','>=',today),
                  where('uid','==',data.reserveUser.uid),
                  where('email','==',"")
                );
                const querySnapshot = await getDocs(q);
                console.log('querySnapshot',querySnapshot)
                if(querySnapshot){
                  const events = querySnapshot.docs.map(doc => {
                    const event = doc.data();
                    console.log('firestore reserverload event',event)
                    // const foundPlan = data.loginUser.reservePlans.find((plan: ReservePlan) => plan.id === event.selectedPlan);
                    // const eventTitle = foundPlan ? foundPlan.title : "タイトル無し";
                    const setEvent: CalendarEvent = {
                      id: doc.id,
                      title: event.title,
                      month: event.month,
                      day: event.day,
                      startSeconds: event.start.seconds,
                      endSeconds: event.end.seconds,
                      startHour: event.startHour,
                      startMinute: event.startMinute,
                      endHour: event.endHour,
                      endMinute: event.endMinute,
                      start: dayjs(event.start.toDate()).format('YYYY-MM-DD HH:mm:ss'), // FirestoreのTimestamp型からDate型に変換
                      end:  dayjs(event.end.toDate()).format('YYYY-MM-DD HH:mm:ss'),  // 同上
                      description: event.description,
                      plan: event.plan,
                      name: event.name,
                      email: event.email,
                      paymentId: event.paymentId,
                      zoomUrl: event.zoomUrl,
                      selectedPlan: event.selectedPlan,
                      borderColor: event.plan.find((plan: ReservePlan) => plan.id === event.selectedPlan)?.color || 'blue',
                    };
                    return setEvent;
                  });
                  return events;
                } else {
                  return false;
                }
              },
                async editor(){
                  const today = dayjs();
                  const twoMonthsAgo = today.subtract(2,'month').toDate();
                  const q = query(collections.reserveDays,
                    where('start','>=',twoMonthsAgo),
                    where('uid','==',data.loginUser.uid),
                  );
                const querySnapshot = await getDocs(q);
                  if(querySnapshot){
                    const events = querySnapshot.docs.map(doc => {
                      const event = doc.data();
                      console.log('firestore load event',event,event.orderId)
                      const foundPlan = data.loginUser.reservePlans.find((plan: ReservePlan) => plan.id === event.selectedPlan);
                      const eventTitle = foundPlan ? foundPlan.title : "タイトル無し";
                      const setEvent: CalendarEvent = {
                        id: doc.id,
                        title: eventTitle || '',
                        start: dayjs(event.start.toDate()).format('YYYY-MM-DD HH:mm:ss'), // FirestoreのTimestamp型からDate型に変換
                        end:  dayjs(event.end.toDate()).format('YYYY-MM-DD HH:mm:ss'),  // 同上
                        description: event.description,
                        plan: event.plan,
                        name: event.name || '',
                        email: event.email || '',
                        paymentId: event.paymentId || '',
                        zoomUrl: event.zoomUrl || '',
                        selectedPlan: event.selectedPlan || '',
                        orderId: event.orderId || '',
                        borderColor: event.plan.find((plan: ReservePlan) => plan.id === event.selectedPlan)?.color || 'blue',
                      }
                      return setEvent;
                    });
                    return events;
                  } else {
                    return false;
                  }
                },

            },
            async order(){
              console.log('order')
              const q = query(collections.orders,where('uid','==',data.loginUser.uid || data.reserveUser.uid));
              const querySnapshot = await getDocs(q);
              console.log('order querySnapshot',querySnapshot)
              const orders = querySnapshot.docs.map(doc => doc.data());
              console.log('firebase order',orders)
              const mappingOrders:OrderFinishUser[] = orders.map((order:DocumentData):OrderFinishUser => this.mapToOrderFinishUser(order));
              data.orderFinishUsers = mappingOrders;
              data.reservedPage.serverItems = mappingOrders;
              console.log('data.orderFinishUsers',data.orderFinishUsers)
            },
            mapToOrderFinishUser(docData: DocumentData): OrderFinishUser {
              // console.log('mapToOrderFinishUser',docData)
              return {
                name: docData.name || '',
                email: docData.email || '',
                // orderDate: `${dayjs(docData.start.toDate()).format('YYYY年MM月DD日 HH時mm分')}～${dayjs(docData.end.toDate()).format('HH時mm分')}` || '',
                orderDate: docData.orderDate || '',
                reserveId: docData.reserveId || '',
                uid: docData.uid || '',
                zoomUrl: docData.zoomUrl || '',
                orderId: docData.orderId || '',
                paymentId: docData.paymentId || '',
                title: docData.title || '',
                start: docData.start || '',
                end: docData.end || '',
                paymentOnly: docData.paymentOnly || false,
              };
            },
        },
        delete:{
          event(eventId:string){
            const docRef = doc(collections.reserveDays,eventId);
            deleteDoc(docRef);
          },
          reserve(event:any){
            console.log('delete reserve',event)
            const docRef = doc(collections.orders,event.orderId);
            deleteDoc(docRef);
            console.log('delete reserve1')
            const reserveDoc = doc(collections.reserveDays,event.reserveId);
            setDoc(reserveDoc,{email:'',orderId:''},{merge:true});
          }
          
        }
      },
      
      
    
})

export async function loginCheck(){
  console.log('ログインチェック',auth,auth.currentUser)
  return auth.currentUser;
}