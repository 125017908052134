<template>
    <v-container class="mb-16">
      <v-row justify="center">
        <v-col lg="4">
          <v-text-field label="お名前" v-model="data.settingData.name">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                メール本文に表示される名前です。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-text-field label="返信先メールアドレス" v-model="data.settingData.replyMailAddress">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                返信先のメールアドレスです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-text-field v-model="data.settingData.testMailAddress" label="テストメールの送信先"></v-text-field>
          <v-btn @click="method.dashboard.sendTestMail" :disabled="data.settingData.clientResponse">
            テストメールを送信する
          </v-btn>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        <v-col lg="4">
          <v-text-field label="予約名" v-model="data.settingData.subject">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                予約完了時のメールの件名です。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <v-col lg="4">
          <v-textarea label="メール本文の前半部" v-model="data.settingData.mainTextBefore">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                メール本文のお客様名と日程の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
              </v-tooltip>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-textarea label="メール本文の後半部" v-model="data.settingData.mainTextAfter">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                メール本文の日程と署名の間の部分です。詳しいレイアウトはテストメールを送信して確認をお願いします。
              </v-tooltip>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-textarea label="メール署名" v-model="data.settingData.signature">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                メール下部に表示される署名です。
              </v-tooltip>
            </template>
          </v-textarea>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-switch :disabled="isLogin" color="blue" v-model="data.settingData.zoom" label="ZOOMの自動ミーティング作成を有効にする"></v-switch>
          このスイッチを有効にすると、カレンダーからの予約時に自動でZOOMのミーティングを作成します。
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.zoom">
        <v-col lg="4">
          以下の項目を4つ埋めて、保存してください。ただしセキュリティのため、ページを移動すると必ず空欄になります。
          <v-text-field label="ZOOM ID" v-model="data.customerSecret.zoomId">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                お持ちのzoomアカウントのIDを入力してください。（通常はメールアドレスです。）
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.zoom">
        <v-col lg="4">
          <v-text-field label="ZOOM accountId" v-model="data.customerSecret.accountId">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                ZOOMのDeveloperで登録したaccountIdを入力してください。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.zoom">
        <v-col lg="4">
          <v-text-field label="ZOOM clientId" v-model="data.customerSecret.clientId">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                ZOOMのDeveloperで登録したclientIdを入力してください。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.zoom">
        <v-col lg="4">
          <v-text-field label="ZOOM clientSecret" v-model="data.customerSecret.clientSecret">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                ZOOMのDeveloperで登録したclientSecretを入力してください。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <!-- <v-row justify="center">
        <v-col lg="4">
          <v-text-field label="Slack Webhook" v-model="data.settingData.slackWebhook">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Slackで発行したWebhookを入力すると、Slackで指定したチャンネルへ<br>予約確定時にメッセージが送信されるようになります。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->
      <v-row justify="center">
        <v-col lg="4">
          <!-- <v-switch :disabled="isLogin" color="blue" v-model="data.customerSecret.SquarePayment" label="Squareによるクレジット決済を有効にする（下記3項目が埋まっていないと決済が出来ずエラーになります）"></v-switch> -->
        </v-col>
      </v-row>
      <template  v-if="data.customerSecret.SquarePayment">
        <v-row justify="center">
  
          <v-col lg="4">
            <v-switch color="blue" v-model="data.customerSecret.SquareTestMode" label="Squareのテストモードを有効にする"></v-switch>
          </v-col>
  
        </v-row>
        <v-row justify="center">
  
          <v-col lg="4">
            <v-text-field label="Square LocationId テスト環境用" v-model="data.settingData.squareLocationIdTest">
              <template v-slot:prepend>
                <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  SquareのDeveloperで発行したlocationIDのテスト用キーです。
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
  
        </v-row>
        <v-row justify="center">
  
          <v-col lg="4">
            <v-text-field label="Square AppID テスト環境用" v-model="data.settingData.squareAppIdTest">
              <template v-slot:prepend>
                <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  SquareのDeveloperで発行したAppIDのテスト用キーです。
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
  
        </v-row>
        <v-row justify="center">
  
          <v-col lg="4">
            <v-text-field label="Square Access token テスト環境用" v-model="data.customerSecret.squareAccessTokenTest">
              <template v-slot:prepend>
                <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                      mdi-help-circle-outline
                    </v-icon>
                  </template>
                  SquareのDeveloperで発行したアクセストークンです。
                </v-tooltip>
              </template>
            </v-text-field>
          </v-col>
  
        </v-row>
      </template>
      <v-row justify="center" v-if="data.customerSecret.SquarePayment">
        <v-col lg="4">
          <v-text-field label="Square LocationID" v-model="data.settingData.squareLocationId">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したロケーションIDです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.customerSecret.SquarePayment">
        <v-col lg="4">
          <v-text-field label="Square AppID" v-model="data.settingData.squareAppId">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したAppIDです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.customerSecret.SquarePayment">
        <v-col lg="4">
          <v-text-field label="Square Access token" v-model="data.customerSecret.squareAccessToken">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                SquareのDeveloperで発行したアクセストークンです。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col lg="4">
          <v-switch color="blue" v-model="data.settingData.stripe.use" label="予約時のStripe決済を有効にする" :disabled="isLogin"></v-switch>
          このスイッチを有効にすると、Stripeクレジット決済が有効になります。
          <!-- <a href="https://dashboard.stripe.com/" target="_blank"><v-btn v-if="data.loginUser.use.stripe && data.settingData.stripe.connectedAccountId">Stripeのダッシュボードを開く</v-btn></a> -->
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.stripe.use">
        <v-col lg="4">
          <!-- <v-switch color="blue" v-model="data.settingData.stripe.test" label="Stripeのテストモードを有効にする"></v-switch>
          このスイッチを有効にすると、クレジット決済がテストモードになります。
          テストモードでは実際に決済はされません。 -->
          <v-dialog max-width="800">
            <template v-slot:activator="{ props: activatorProps}">
              <v-btn v-bind="activatorProps" text="Stripeの設定" @click="method.stripe.createAccount"></v-btn>
            </template>

            <template v-slot:default="{ isActive }">
              <StripeaccountCreate></StripeaccountCreate>
            </template>
          </v-dialog>
          
        </v-col>
      </v-row>
      <!-- <a href="https://dashboard.stripe.com/" target="_blank"><v-btn v-if="data.loginUser.use.stripe && data.settingData.stripe.connectedAccountId">Stripeのダッシュボードを開く</v-btn></a> -->
      <!-- <v-row justify="center">
        <v-col lg="4">
          <v-switch color="blue" v-model="data.settingData.stores.use" label="予約時のStores決済を有効にする" :disabled="isLogin"></v-switch>
          このスイッチを有効にすると、Storesクレジット決済が有効になります。<br>
          両方有効にした場合、Stripeが優先されます。
        </v-col>
      </v-row>
      <v-row justify="center" v-if="data.settingData.stores.use">
        <v-col lg="4">
          <v-text-field label="Stores APIキー" v-model="data.customerSecret.storesKey">
            <template v-slot:prepend>
              <v-tooltip location="start" bottom>
                <template v-slot:activator="{ props }">
                  <v-icon v-bind="props">
                    mdi-help-circle-outline
                  </v-icon>
                </template>
                Storesで発行したAPIキーを入力してください。<br>
                セキュリティのため、保存後は表示されません。
              </v-tooltip>
            </template>
          </v-text-field>
        </v-col>
      </v-row> -->
    </v-container>
    <v-btn @click="method.dashboard.saveSettings()"
        style="position: fixed; bottom: 5%; right: 5%;">
          保存する
        </v-btn>
  </template>

<script setup lang="ts">
import {data} from '@/comporsables/useData'
import {method} from '@/comporsables/useMethod'
import {computed} from 'vue'
import StripeaccountCreate from '@/components/StripeaccountCreate.vue';

const isLogin = computed(() => {
  if(data.loginUser.uid === ''){
    return true;
  } else {
    return false;
  }
})

// const dialog = computed (() => {
//   if(!data.settingData.dialog){
//     return false;
//   } else {
//     return data.settingData.dialog.stripe;}
// })

</script>