<template>
    <v-container fill-height>
        <v-row align-content="center" justify="center">
          <v-col lg="6" align="center">
                <v-card>
                    <v-card-title>お申し込みが完了しました</v-card-title>
                    <v-card-text>
                        <p>お申し込みが完了いたしました。</p>
                        <p>ご入力頂いたメールアドレス宛に、詳細を記載したものを送信しますので、ご確認下さい。</p>
                    </v-card-text>
                    <v-card-actions class="justify-center">
                        <v-btn @click="backHome">元のページへ戻る</v-btn>
                    </v-card-actions>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '../comporsables/useData'
import { method } from '../comporsables/useMethod'
import { onMounted } from 'vue'

const backHome = () => {
    location.href= `${window.location.origin}/${data.reserveUser.uid}/`;
}

onMounted(() => {
    localStorage.removeItem('reserveData')
    localStorage.removeItem('reserveUser')
    localStorage.removeItem('settingData')
    // method.reserve.reserveComplete();
    
})
</script>