<template>
  <v-container class="fill-height">
    <v-row align="center" justify="center" class="fill-height">
      <v-col cols="12" class="text-center">
        <v-progress-circular :width="7" :size="70" color="primary" indeterminate></v-progress-circular>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import { data } from '../comporsables/useData'
import { method } from '../comporsables/useMethod'
import { useRouter,useRoute } from 'vue-router'
import { onMounted } from 'vue';
const router = useRouter();
const route = useRoute();

onMounted(() => {
  if(route.query.session_id || route.query.stores){
    const temp = localStorage.getItem('reserveData')
    const temp2 = localStorage.getItem('reserveUser')
    const temp3 = localStorage.getItem('settingData')
    if(temp !== null && temp2 !== null && temp3 !== null){
      let reserveData = JSON.parse(temp);
      data.reserveUser = JSON.parse(temp2);
      data.settingData = JSON.parse(temp3);
      console.log('stripe redirect reserveData',reserveData)
      if(route.query.session_id){
        reserveData.paymentId = route.query.session_id;
      }else if(route.query.stores){
        reserveData.paymentId = data.settingData.stores.id;
      }
      
      method.reserve.submitSection2(router,reserveData);
    }
    
  }
  
})


</script>