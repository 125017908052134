<template>
  <v-app>
    <v-app-bar-nav-icon v-if="route.meta.dashboard"
        @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>
    <v-main>
      <v-navigation-drawer v-if="route.meta.dashboard"
        v-model="drawer"
      >
        <v-list>
          <v-list-item v-for="(item,index) in data.dashboardPageList" @click="router.push(item.to)" :key="item.to" :disabled="!data.loginUser.uid">
            <v-list-item-title>{{item.title}}</v-list-item-title>
          </v-list-item>
        </v-list>
        <v-spacer></v-spacer>
        <v-list>
          <v-list-item @click="method.logout(router)">
            <v-list-item-title>ログアウト</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-navigation-drawer>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script setup lang="ts">
import { useRoute,useRouter } from 'vue-router'
import { ref } from 'vue'
import { data } from './comporsables/useData'
import { method } from './comporsables/useMethod'
const route = useRoute();
const router = useRouter();
const drawer = ref(null);

</script>
