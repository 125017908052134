import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'
import Reserve from '@/views/Reserve.vue'
import ReserveComplete from '@/views/ReserveComplete.vue'
import Dashboard from '@/views/Dashboard/Dashboard.vue'
import PlanList from '@/views/Dashboard/PlanList.vue'
import AddReserveCalendar from '@/views/Dashboard/AddReserveCalendar.vue'
import ReserveList from '@/views/Dashboard/ReserveList.vue'
import Settings from '@/views/Dashboard/Settings.vue'
import SignUp from '@/views/SignUp.vue'
import Shipping from '@/views/shipping.vue'

import { getAuth } from "firebase/auth";

const routes: Array<RouteRecordRaw> = [
  {
    path: '/:uid',
    name: 'home',
    component: Home,
    props: true
  },
  {
    path: '/shipping/:uid',
    name: 'shipping',
    component: Shipping,
    props: true
  },
  {
    path: '/:uid/Reserve',
    name: 'Reserve',
    component: Reserve
  },
  {
    path: '/:uid/ReserveComplete',
    name: 'ReserveComplete',
    component: ReserveComplete
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
    meta: { dashboard:true }
  },
  {
    path: '/PlanList',
    name: 'PlanList',
    component: PlanList,
    meta: { requiresAuth: true,dashboard:true }
  },
  {
    path: '/AddReserveCalendar',
    name: 'AddReserveCalendar',
    component: AddReserveCalendar,
    meta: { requiresAuth: true,dashboard:true }
  },
  {
    path: '/ReserveList',
    name: 'ReserveList',
    component: ReserveList,
    meta: { requiresAuth: true,dashboard:true }
  },
  {
    path: '/Settings',
    name: 'Settings',
    component: Settings,
    meta: { requiresAuth: true,dashboard:true }
  },
  {
    path: '/SignUp',
    name: 'SignUp',
    component: SignUp
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
});

// ナビゲーションガードの追加
router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  const auth = getAuth();
  const currentUser = auth.currentUser;

  if (requiresAuth && !currentUser) {
    next('/Dashboard'); // ログインしていない場合はサインアップページにリダイレクト
  } else {
    next(); // ログインしている場合はそのまま進む
  }
});
export default router
