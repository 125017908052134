import { reactive } from 'vue'
import { method } from './useMethod'
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction'


export const data:AllData = reactive({
    login:{
        check:false,
        button:false,
    },
    loginUser: {
        name: '',
        email: '',
        password: '',
        uid: '',
        reservePlans: [],
        selectedUse: [],
        use:{
            stripe:false,
        },
        resetPassword:false,
    },
    settingData:{
        payment: false,
        zoom: false,
        name: '', // 名前のデフォルト値は空文字列
        mailAddress: '', // メールアドレスのデフォルト値は空文字列
        replyMailAddress: '', // 返信用メールアドレスのデフォルト値も空文字列
        subject: 'No Subject', // 件名のデフォルト値
        mainTextBefore: '', // メール本文の前文のデフォルト値は空文字列
        mainTextAfter: '', // メール本文の後文のデフォルト値も空文字列
        color: '#000000', // デフォルトの色（例えば黒）
        slackWebhook: '', // Slack Webhook URLのデフォルト値は空文字列
        squareLocationIdTest: '', // SquareのテストロケーションIDのデフォルト値は空文字列
        squareAppIdTest: '', // SquareのテストアプリIDのデフォルト値も空文字列
        squareLocationId: '', // SquareのロケーションIDのデフォルト値は空文字列
        squareAppId: '', // SquareのアプリIDのデフォルト値も空文字列
        signature: '', // 署名のデフォルト値は空文字列
        testmode: false, // テストモードのデフォルト値はfalse
        testMailAddress: '', // テスト用メールアドレスのデフォルト値は空文字列
        clientResponse: false, // クライアントからのレスポンス要否のデフォルト値はfalse
        stripe: {// Stripeアカウント情報のデフォルト
            accountCreatePending : false,
            onboardingExited : false,
            error : false,
            connectedAccountId : null,
            stripeConnectInstance : null,
            connectedAccountIdTest : null,
            test:false,
            use:false,
        }, 
        stores:{
            use:false,
        },
        
    },
    customerSecret:{
        // SquareTestMode: false,
        docId: "",
        zoomId: "",
        accountId: "",
        clientId: "",
        clientSecret: "",
        SquarePayment: "",
        squareAccessTokenTest: "",
        squareAccessToken: "",
        stripe: {
            secretKey: "",
            publishableKey: "",
        },
        stores:{
            key:'',
        },
    },
    dashboardPageList: [
        {
            title: 'ダッシュボードトップ',
            to: '/Dashboard',
        },
        {
            title: '各種設定',
            to: '/Settings',
        },
        {
            title: '予約一覧',
            to: '/ReserveList',
        },
        {
            title: 'プラン一覧',
            to: '/PlanList',
        },
        {
            title: '予約日追加',
            to: '/AddReserveCalendar',
        },
    ],
    reserveUser: {
        uid:'',
        reserveDate: '',
        name: '',
        email: '',
        selectedPlan: {},
        plans: [],
        submit: false,
    },
    reservedPage:{
        addReserveModal:false,
        serverItems:[],
        sortBy:[
            { key: 'orderDate', order: 'desc' },
        ],
        reserveHeaders:[
            { title: 'お名前',  key: 'name', },
            { title: 'メールアドレス', key: 'email', },
            { title: 'プラン名', key: 'title', },
            { title: '予約日', align: 'start',key: 'orderDate', },
            { title: '予約編集', key: 'actions', sortable: false },
        ],
        paymentOnlyHeaders:[
            { title: 'お名前', align: 'start', key: 'name', },
            { title: 'メールアドレス', key: 'email', },
            { title: 'プラン名', key: 'title', },
            { title: '支払日', align: 'start',key: 'orderDate', },
            { title: '顧客情報編集', key: 'actions', sortable: false },
        ],
        show:false,
        showPaymentOnly:false,
        dialog:false,
        dialogDelete:false,
        dialogPaymentOnly:false,
        dialogPaymentOnlyDelete:false,
        editedItem:{
            name:'',
            email:'',
            zoomUrl:'',
            reserveId:'',
            paymentId:'',
            orderDate:'',
            uid:'',
            title:'',
            orderId:'',
            start:'',
            end:'',
        },
        reserveSet:[{
            month:'',
            day:'',
            startHour:0,
            startMinute:0,
            endHour:0,
            endMinute:0,
            eventId:'',
            eventDay:'',
            title:'',
            description:'',
            plan:[],
        }],
    },
    calendar: {
        locale: "ja-JP",
        plugins: [dayGridPlugin,interactionPlugin],
        showModal: false,
        events: [],
        selectedEventDetails: [],
        calendarId: '',
        eventClick: method.calendar.eventClick,
    },
    dashboardCalendar: {
        addReserveModal: false,
        tooltip1:false,
        tooltip2:false,
        tooltip3:false,
        locale: "ja-JP",
        plugins: [dayGridPlugin,interactionPlugin],
        showModal: false,
        events: [],
        selectedEventDetails:[],
        menu: false,
        menuX: 0,
        menuY: 0,
        menuItems: [
            {
                text: '',
                action: '',
            },
        ],
        exchangeTimesReverse: method.dashboardCalendar.exchangeTimesReverse,
        exchangeTimes: method.dashboardCalendar.exchangeTimes,
        pushSelectedEvents: method.dashboardCalendar.pushSelectedEvents,
        eventClick: method.dashboardCalendar.eventClick,
        dateClick: method.dashboardCalendar.dateClick,
        eventDidMount: method.dashboardCalendar.eventDidMount,
        dayCellDidMount: method.dashboardCalendar.dayCellDidMount,
        menuAction: method.dashboardCalendar.menuAction,
        existPlansCheck: method.dashboard.existPlansCheck,
        event:{
            copyData:[],
            copy: method.dashboardCalendar.event.copy,
            allCopy: method.dashboardCalendar.event.allCopy,
            paste: method.dashboardCalendar.event.paste,
            delete: method.dashboardCalendar.event.delete,
        },
    },
    orderFinishUser:{
        name:'',
        email:'',
        zoomUrl:'',
        reserveId:'',
        paymentId:'',
        orderDate:'',
        uid:'',
        title:'',
        orderId:'',
        start:'',
        end:'',
    },
    orderFinishUsers:[],
    orderHeaders:{
        title: '予約プラン',
        email: 'メールアドレス',
        reserveId: '予約ID',
        orderDate: '予約日',
      },
});