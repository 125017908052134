<template>
    <v-container>
        <v-row>
            <v-col>
                <full-calendar
                   :options="data.calendar"
                ></full-calendar>
                <v-card>
                    <v-dialog v-model="data.calendar.showModal" max-width="500px">
                        <v-card lg="6">
                            
                            <div v-for="(item,index) in data.calendar.selectedEventDetails">
                            <v-card-title>{{ item.title }}</v-card-title>
                            <v-card-text>
                                開始時間：{{item.month}}月{{item.day}}日 {{ item.startHour }}時{{ item.startMinute }}分<br>
                                終了時間：{{item.month}}月{{item.day}}日 {{ item.endHour }}時{{ item.endMinute }}分
                                <div>
                                    <v-text-field v-model="data.reserveUser.name" label="お名前"></v-text-field>
                                    <v-text-field v-model="data.reserveUser.email" label="メールアドレス"></v-text-field>
                                </div>
                                <v-radio-group v-model="data.reserveUser.selectedPlan">
                                    <div v-for="plan in item.plan" :key="plan.id">
                                        <template v-if="item.selectedPlan === plan.id">
                                        <v-radio :label="`料金：${plan.price}円`" :value="plan"></v-radio>
                                        <v-textarea readonly v-model="plan.description" label="プラン詳細"></v-textarea>
                                        </template>
                                    </div>
                                </v-radio-group>
                            </v-card-text>
                            </div>
                            <v-card-actions>
                                <v-btn color="primary" @click="data.calendar.showModal = false">キャンセル</v-btn>
                                <v-btn @click="method.reserve.submit(router)"
                                    :disabled="!data.reserveUser.name || !data.reserveUser.email || !data.reserveUser.selectedPlan.id"
                                >予約する</v-btn>
                            </v-card-actions>
                        </v-card>
                    </v-dialog>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script setup lang="ts">
import { data } from '../comporsables/useData'
import { method } from '../comporsables/useMethod'
import FullCalendar from '@fullcalendar/vue3'
import { useRouter,useRoute } from 'vue-router';
import { onMounted } from 'vue'

const route = useRoute()
const router = useRouter();

onMounted(async () => {
    localStorage.removeItem('reserveData')
    localStorage.removeItem('reserveUser')
    localStorage.removeItem('settingData')
    console.log('onMounted',route.params.uid)
    const calendarId = Array.isArray(route.params.uid) ? route.params.uid[0] : route.params.uid;
    data.reserveUser.uid = calendarId;
    console.log('onMounted',data.reserveUser.uid)
    await method.calendar.eventLoad();
    if(route.params.plan0){
    data.calendar.events = data.calendar.events.filter((event) =>{
        return event.plan.filter((plan,index) => plan.id === route.params[`plan${index}`])
    })
    console.log('onMounted',data.calendar.events)
    method.setEvents();
}
})

</script>

<style>
@media screen and (max-width: 600px){
    .fc-view-harness
{
	height: 426px !important;
}
}
</style>